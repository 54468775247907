import { Episode } from '../models/podcasts';

export function trackAddToListenLaterClick(episode: Episode) {
  window.plausible('AddToListenLaterClick', { props: { episode_id: episode.id } });
}

export function trackDownloadClick(episode: Episode) {
  window.plausible('DownloadClick', { props: { episode_id: episode.id } });
}

export function trackPlayNewEpisodeClick(episode: Episode) {
  window.plausible('PlayNewEpisodeClick', { props: { episode_id: episode.id } });
}

export function trackSkipAdInPlausible({
  subscriptionStore,
  subscriptionStatus,
  duration,
  credits,
}: {
  subscriptionStore: string;
  subscriptionStatus: string;
  duration: number;
  credits: number;
}) {
  window.plausible('SkipAd', {
    props: {
      subscription_store: subscriptionStore || 'no_store',
      subscription_status: subscriptionStatus || 'no_subscription',
      duration: Math.round(duration),
      credits,
    },
  });
}
