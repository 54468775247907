import { clamp } from 'lodash-es';
import { Fragment, h } from 'preact';
import { useLayoutEffect, useRef, useState } from 'preact/hooks';
import { useQuery } from 'react-query';
import Forward30Icon from '../../assets/icons/forward-30.svg';
import Replay10Icon from '../../assets/icons/replay-10.svg';
import {
  AudioState,
  currentDuration,
  currentEpisode,
  currentError,
  currentSeek,
  getCurrentEpisode,
  getCurrentSeek,
  getRequestedSeek,
  getRequestedState,
  isFixingAudio,
  playbackRate,
  state as stateSignal,
} from '../../models/audio-signals';
import {
  loadNewEpisode,
  nextAdThresholdSeconds,
  pause,
  play,
  reset,
  seekTo,
} from '../../models/audio-state';
import { useSessionStore } from '../../models/session';
import { getAdSkipCredits, useAdSkipsLeft } from '../../models/usage-data';
import { useIsAppDeveloper } from '../../models/user';
import getAdsStatus from '../../utils/ads-status';
import { fetchEpisode } from '../../utils/api';
import { reportError } from '../../utils/error-handling';
import { classNames, getEpisodeDuration, secondsToTime } from '../../utils/helpers';
import style from './style.scss';

const AudioPlayer = () => {
  const state = stateSignal.value;
  const episode = getCurrentEpisode();
  const adsStatus = episode ? getAdsStatus(episode, true, false) : '';

  useCheckForUpdatedAds(state);

  const showDebuggingUI = useIsAppDeveloper();

  return (
    <div class={style.player}>
      {showDebuggingUI && (
        <>
          <h1>
            Player: {state} {secondsToTime(roundSeek(getCurrentSeek()))}
          </h1>
          <p>{currentError.value || 'No issues'}</p>
        </>
      )}
      {adsStatus}
      <Seek />
      <div class={style.playerButtonsTopRow}>
        <SkipBack10 />
        <MainButton />
        <SkipForward30 />
      </div>
      <div class={style.playerButtonsBottomRow}>
        <RateButton />
        {/* <OptionsButton /> */}
      </div>
    </div>
  );
};

const { wasAdRecentlySkipped } = useSessionStore.getState();

const Seek = () => {
  const seek = currentSeek.value || 0;
  const episode = currentEpisode.value;
  const duration = currentDuration.value || getEpisodeDuration(episode);
  const ads = (episode && episode.ads) || [];

  const barRef = useRef<HTMLDivElement>(null);
  const dragEndTimeRef = useRef(0);
  // const lastRequestedSeek = useRef<[number, number]>(); // used to prevent jitter

  // Seeking (dragging/clicking)
  const [draggingPointerId, setDraggingPointerId] = useState<number>();
  const [barRect, setBarRect] = useState<DOMRect>();
  const [pendingSeek, setPendingSeek] = useState<number>();
  const requestedSeek = getRequestedSeek();
  let visibleSeek =
    typeof pendingSeek === 'number'
      ? pendingSeek
      : !Number.isNaN(requestedSeek)
        ? requestedSeek
        : seek;
  if (visibleSeek > duration) visibleSeek = duration;

  // console.log({ visibleSeek, pendingSeek, requestedSeek, seek, duration });

  const timePassed = secondsToTime(roundSeek(visibleSeek));
  const timeLeft = secondsToTime(Math.round(duration) - roundSeek(visibleSeek));
  const progressRatio = visibleSeek / duration;

  useLayoutEffect(() => {
    if (barRef.current) {
      setBarRect(barRef.current.getBoundingClientRect());
    }
  }, [barRef.current, window.innerWidth]);

  const totalAdSkipCredits = useAdSkipsLeft().total;

  const onPointerDown = (event: PointerEvent) => {
    setDraggingPointerId(event.pointerId);
    setBarRect(barRef.current!.getBoundingClientRect());
    (event.currentTarget as HTMLElement).setPointerCapture(event.pointerId);
  };

  const onPointerMove = (event: PointerEvent) => {
    if (typeof draggingPointerId === 'number' && duration) {
      const seekRatio = clamp((event.pageX - barRect!.x) / barRect!.width, 0, duration);
      setPendingSeek(duration * seekRatio);
    }
  };

  const onPointerUpOrCancel = (event: PointerEvent) => {
    setDraggingPointerId(undefined);
    (event.currentTarget as HTMLElement).releasePointerCapture(event.pointerId);
    if (pendingSeek !== undefined) {
      seekTo(pendingSeek, 'drag seek bar');
      setPendingSeek(undefined);
      dragEndTimeRef.current = Date.now();
    }
  };

  const onClick = (event: MouseEvent) => {
    if (Date.now() - dragEndTimeRef.current < 100) return;
    const seekRatio = clamp((event.pageX - barRect!.x) / barRect!.width, 0, duration);
    seekTo(duration * seekRatio, 'click seek bar');
  };

  return (
    <div
      class={style.seek}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUpOrCancel}
      onPointerCancel={onPointerUpOrCancel}
      onClick={onClick}
    >
      <div class={style.seekTimePassed}>{timePassed}</div>
      <div class={style.seekBar} ref={barRef}>
        <div
          class={style.seekBarProgressOverlay}
          style={seek ? `width: ${100 * progressRatio}%` : 'width: 0px'}
        ></div>
        {ads.map((ad) => {
          const startPercent = 100 * (ad.startTime / duration);
          const endPercent = 100 * (ad.endTime / duration);
          const wasRecentlySkipped = episode
            ? wasAdRecentlySkipped(episode.id, ad.startTime)
            : false;
          const hasEnoughCreditsToSkipAd = !totalAdSkipCredits
            ? // No ad credits left
              false
            : // Ad is *before* seek, meaning they could move back and re-skip it
              visibleSeek > ad.startTime + nextAdThresholdSeconds
              ? true
              : // Ad can be skipped even after skipping all the ads between it and the current seek position
                getAdSkipCredits(
                  ads.filter(
                    (_ad) =>
                      _ad.startTime + nextAdThresholdSeconds > visibleSeek &&
                      _ad.startTime < ad.startTime
                  )
                ) < totalAdSkipCredits;

          return (
            <div
              class={style.seekBarAdOverlay}
              style={{
                left: `${startPercent}%`,
                width: `${endPercent - startPercent}%`,
                backgroundColor: wasRecentlySkipped
                  ? '#aaa'
                  : hasEnoughCreditsToSkipAd
                    ? '#16a34a'
                    : '', // Default to red if it can't be skipped
              }}
            ></div>
          );
        })}
        <div
          class={style.seekDragger}
          style={`margin-left: ${100 * progressRatio}%`}
        ></div>
      </div>
      <div class={style.seekTimeLeft}>{timeLeft}</div>
    </div>
  );
};

const MainButton = () => {
  const state = stateSignal.value;
  const requestedState = getRequestedState();

  if (state === 'unloaded') {
    return <PlayButton onClick={() => loadAndPlay()}></PlayButton>;
  }
  if (state === 'loading' || isFixingAudio.value) {
    return <LoadingButton onClick={() => reset()}></LoadingButton>;
  }
  if (state === 'paused' || (state === 'seeking' && requestedState === 'paused')) {
    return <PlayButton onClick={() => play()}></PlayButton>;
  }
  if (state === 'playing' || (state === 'seeking' && requestedState === 'playing')) {
    return <PauseButton onClick={() => pause()}></PauseButton>;
  }
  if (state === 'seeking') {
    // Happens when iOS native wrapper is skipping ad (instead of JS client doing it)
    return <LoadingButton onClick={() => reset()}></LoadingButton>;
  }
  if (state !== 'error') {
    console.error('Unhandled MainButton state', { state, requestedState });
  }
  // Error state (but also used in case state isn't handled for some reason)
  return (
    <PlayButton
      onClick={() => {
        const idealSeek = getRequestedSeek() || getCurrentSeek() || 0;
        reset();
        loadAndPlay(idealSeek);
      }}
    ></PlayButton>
  );
};

function loadAndPlay(idealSeek = 0) {
  if (currentEpisode.value) {
    loadNewEpisode(currentEpisode.value, idealSeek);
    play();
  } else {
    reportError('Unable to loadAndPlay() - no episode');
  }
}

const PlayButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      onClick={onClick}
    >
      <svg class={style.playIcon} viewBox="0 0 128 128">
        <path d="M24 16l80 48-80 48z"></path>
      </svg>
    </button>
  );
};

const PauseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      onClick={onClick}
    >
      <svg class={style.pauseIcon} viewBox="0 0 128 128">
        <path d="M16 16h40v96h-40zM72 16h40v96h-40z"></path>
      </svg>
    </button>
  );
};

const LoadingButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      onClick={onClick}
    >
      <svg class={style.loadingIcon} viewBox="0 0 128 128">
        <path d="M64 128c-17.095 0-33.167-6.657-45.255-18.745s-18.745-28.16-18.745-45.255c0-12.105 3.398-23.892 9.826-34.087 6.25-9.912 15.083-17.922 25.545-23.163l5.375 10.729c-8.505 4.261-15.687 10.773-20.769 18.834-5.218 8.276-7.976 17.85-7.976 27.686 0 28.673 23.327 52 52 52s52-23.327 52-52c0-9.837-2.758-19.41-7.976-27.686-5.082-8.060-12.264-14.573-20.769-18.834l5.375-10.729c10.462 5.241 19.295 13.25 25.544 23.163 6.428 10.195 9.825 21.982 9.825 34.087 0 17.095-6.657 33.167-18.745 45.255s-28.16 18.745-45.255 18.745z"></path>
      </svg>
    </button>
  );
};

const SkipBack10 = () => {
  const seconds = -10;
  const handleClick = () => {
    let seek = getRequestedSeek();
    if (Number.isNaN(seek)) seek = getCurrentSeek();
    seekTo(seek + seconds, `click SkipBack10`);
  };
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      onClick={handleClick}
    >
      <Replay10Icon />
    </button>
  );
};

const SkipForward30 = () => {
  const seconds = 30;
  const handleClick = () => {
    let seek = getRequestedSeek();
    if (Number.isNaN(seek)) seek = getCurrentSeek();
    seekTo(seek + seconds, `click SkipForward30`);
  };
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      onClick={handleClick}
    >
      <Forward30Icon />
    </button>
  );
};

const RateButton = () => {
  const [isOpen, setOpen] = useState(false);
  const onInput = (event: any) =>
    (playbackRate.value = parseFloat(event.target.value) || 1.0);
  const min = 0.7;
  const max = 2.5;
  const step = 0.1;

  return (
    <>
      <label class="flex-grow">
        <span class="hidden">Set Playback Rate</span>
        <button
          class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
          style={{ '--button-height': '36px' }}
          onClick={() => setOpen(!isOpen)}
        >
          {playbackRate.value}×
        </button>
        {isOpen && (
          <>
            <input
              type="range"
              min={min}
              max={max}
              step={step}
              value={playbackRate}
              list="playback_rate_list"
              class="w-full text-xs"
              onInput={onInput}
            />
            <datalist id="playback_rate_list">
              {fillArrayOfNumbers(min, max, step).map((num) => (
                <option key={num}>{num}</option>
              ))}
            </datalist>
          </>
        )}
      </label>
    </>
  );
};

function fillArrayOfNumbers(min: number, max: number, step: number) {
  return new Array(Math.ceil((max - min) / step)) // the array's length as a function of max, min, and step
    .fill(undefined)
    .map((_, i) => i * step + min); // a step is a product of i and step + min
}

const OptionsButton = () => {
  return (
    <button
      class={classNames('button button--circle-icon', style.circleButtonForPlayer)}
      style={{ '--button-height': '2rem' }}
      onClick={() => {}}
    >
      ...
    </button>
  );
};

// Round down, unless very close to the next number or close to the duration
function roundSeek(seek: number) {
  const decimal = seek % 1;
  const rounded = Math.round(seek);

  // Close to duration (duration may be an integer but seek is a float)
  if (
    rounded === currentDuration.value ||
    rounded === Math.round(currentDuration.value)
  ) {
    return rounded;
  }

  // Round down until very close to next second
  if (1 - decimal < 0.05) return rounded;
  return Math.floor(seek);
}

function useCheckForUpdatedAds(state: AudioState) {
  // If episode doesn't yet have ads, re-fetch it every minute while the user is
  // playing audio, or every 5 minutes otherwise
  const episode = currentEpisode.value;
  const shouldReFetch = Boolean(episode && !episode.ads && !episode.url);
  const queryKey = shouldReFetch
    ? ['podcast', episode!.podcastId, episode!.guidHash]
    : '';
  const queryFn = shouldReFetch
    ? () => fetchEpisode(episode!.podcastId, episode!.guidHash)
    : () => null;
  const queryOptions = shouldReFetch
    ? {
        enabled: true,
        refetchInterval: 1000 * 60 * (state === 'playing' ? 1 : 5),
        refetchIntervalInBackground: state === 'playing',
      }
    : {
        enabled: false,
      };
  useQuery(queryKey, queryFn, queryOptions);
}

export default AudioPlayer;
