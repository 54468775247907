import { signal } from '@preact/signals';
import { sum, throttle } from 'lodash-es';
import create from 'zustand';
import shallow from 'zustand/shallow';
import { APIError, requestApi } from '../utils/api';
import { reportError } from '../utils/error-handling';
import {
  exposeOnWindow,
  isEpisodeIdentifier,
  isPodcastIdentifier,
  platform,
} from '../utils/helpers';
import { getInitialValue, onTabHidden, setLazyValue } from '../utils/localstorage';
import { trackSkipAdInPlausible } from '../utils/plausible-events';
import { currentEpisode, getState } from './audio-signals';
import debugLog from './debug';
import { EpisodeCompoundId, PodcastId } from './podcasts';
import { useSessionStore } from './session';
import { getUser } from './user';

const log = (...args: any[]) => {
  debugLog({ logName: 'usage', logColor: 'orange' }, ...args);
};

//
// Track skipped ads and roughly how long podcasts are listened to
//

type UsageStore = {
  // Track ad skips & minutes listened of each podcast
  podcasts: Record<
    string,
    {
      adSkips: number;
      minutesListened: number;
    }
  >;
  // Store ad skips remaining (provided by API)
  adSkipsLeft: {
    free: number;
    expiring: number;
    forever: number;
    renewalTimestamp: number | undefined;
  } | null;
  // Users current Stripe subscription status
  subscriptionStatus: string | undefined;
  subscriptionStore: string | undefined;
  accountManagementUrl: 'STRIPE_CUSTOMER_PORTAL' | `${string}://${string}` | undefined;
};
export const totalAdSkipsLeft = signal<number>(NaN);

type UsageStoreActions = {
  _trackAdSkip: (podcastId: PodcastId, duration: number) => void;
  bumpMinutesListened: (podcastId: PodcastId) => void;
  clearUsageAndSubscriptionData: () => void;
};

const useUsageStore = create<UsageStore & { actions: UsageStoreActions }>()(
  (set, get) => ({
    podcasts: getInitialValue(
      'UsageStore__podcasts',
      (val) =>
        Boolean(
          typeof val === 'object' &&
            val &&
            Object.keys(val).every((id) => isPodcastIdentifier(id)) &&
            Object.values(val).every((obj) =>
              isObjectWithProperties(obj, ['adSkips', 'minutesListened'])
            )
        ),
      {}
    ),
    adSkipsLeft: getInitialValue(
      'UsageStore__asl',
      (val) =>
        isObjectWithProperties(val, ['free', 'expiring', 'forever']) &&
        Object.values(val!).every(
          (int) => Number.isInteger(int) && (int as number) >= 0
        ),
      null
    ),
    subscriptionStatus: undefined,
    subscriptionStore: undefined,
    accountManagementUrl: undefined,
    actions: {
      _trackAdSkip: (podcastId, duration) => {
        const { podcasts, subscriptionStatus, subscriptionStore } = get();
        const podcast = podcasts[podcastId] || { adSkips: 0, minutesListened: 0 };
        let { adSkips, minutesListened } = podcast;
        const credits = durationToAdSkipCredits(duration);
        adSkips += credits;
        log(`Consumed ${credits} ad skip credits`);
        set({
          podcasts: { ...podcasts, [podcastId]: { adSkips, minutesListened } },
        });
        trackSkipAdInPlausible({
          subscriptionStore: subscriptionStore || 'no_store',
          subscriptionStatus: subscriptionStatus || 'no_subscription',
          duration,
          credits,
        });
      },
      bumpMinutesListened: (podcastId) => {
        const { podcasts } = get();
        const podcast = podcasts[podcastId] || { adSkips: 0, minutesListened: 0 };
        let { adSkips, minutesListened } = podcast;
        set({
          podcasts: {
            ...podcasts,
            [podcastId]: { adSkips, minutesListened: minutesListened + 1 },
          },
        });
      },
      clearUsageAndSubscriptionData: () =>
        set({
          podcasts: {},
          adSkipsLeft: null,
          // Reset user-related data as well
          subscriptionStatus: undefined,
          subscriptionStore: undefined,
          accountManagementUrl: undefined,
        }),
    },
  })
);

//
// Expose actions
//

export const usageActions = useUsageStore.getState().actions;

export function trackAdSkipUnlessRecent(
  episodeId: EpisodeCompoundId,
  startTime: number,
  endTime: number
) {
  if (!isEpisodeIdentifier(episodeId))
    return reportError('Invalid episodeId during ad-skip tracking', { episodeId });

  if (!(startTime < endTime))
    return reportError('Invalid start/end time during ad-skip tracking', {
      episodeId,
      startTime,
      endTime,
    });

  const { wasAdRecentlySkipped, trackRecentAdSkip } = useSessionStore.getState();
  const wasRecentlySkipped = wasAdRecentlySkipped(episodeId, startTime);

  // Add this as the most recent ad skip (after checking if it was recently skipped)
  trackRecentAdSkip(episodeId, startTime);

  if (wasRecentlySkipped) return log('Ad recently skipped. Not consuming credit...');

  // Track ad skip & consume credits
  usageActions._trackAdSkip(episodeId.split('|')[0] as PodcastId, endTime - startTime);
}

export const useAdSkipsLeft = () => useUsageStore(_getAdSkipsLeft, shallow);
export const getAdSkipsLeft = () => _getAdSkipsLeft(useUsageStore.getState());

function _getAdSkipsLeft(s: UsageStore) {
  if (!s.adSkipsLeft) {
    return {
      free: 0,
      expiring: 0,
      forever: 0,
      total: 0,
      isLoading: true,
      description: 'Loading ad-skips...',
    };
  }

  let pendingSync = sum(Object.values(s.podcasts).map((o) => o.adSkips));
  const [free, expiring, forever] = subtractFromValues(
    [s.adSkipsLeft.free, s.adSkipsLeft.expiring, s.adSkipsLeft.forever],
    pendingSync
  );
  const total = free + expiring + forever;
  // console.log({ free, expiring, forever, total });

  const purchasePlanSuffix = 'Please purchase a paid plan to support podcast creators.';

  const adSkipQuantityText =
    [
      free ? `${free} free` : '',
      expiring ? `${expiring} monthly` : '',
      forever ? `${forever} non-expiring` : '',
    ]
      .filter(Boolean)
      .join(' & ') || 'No';

  const renewsInDays = s.adSkipsLeft.renewalTimestamp
    ? (s.adSkipsLeft.renewalTimestamp - Date.now()) / (1000 * 60 * 60 * 24)
    : NaN;

  const renewalText =
    renewsInDays > 1
      ? `Monthly ad-skips will renew in ${Math.round(renewsInDays)} days.`
      : renewsInDays < 1
        ? `Monthly ad-skips will renew in ${Math.ceil(renewsInDays * 24)} hours.`
        : '';

  const adSkipText = total === 1 ? 'ad-skip' : 'ad-skips';

  return {
    free,
    expiring,
    forever,
    total,
    isLoading: false,
    description:
      total === free
        ? `${adSkipQuantityText} ${adSkipText} left. ${purchasePlanSuffix}`
        : `${adSkipQuantityText} ${adSkipText} left. ${renewalText}`,
  };
}

export const useSubscriptionStatus = () => useUsageStore((s) => s.subscriptionStatus);
export const useSubscriptionStore = () => useUsageStore((s) => s.subscriptionStore);
export const useAccountUrl = () => useUsageStore((s) => s.accountManagementUrl);

//
// Save updates in LocalStorage
//

useUsageStore.subscribe(({ podcasts, adSkipsLeft }) => {
  // Update signal
  const { total, isLoading } = getAdSkipsLeft();
  totalAdSkipsLeft.value = isLoading ? NaN : total;

  setLazyValue('UsageStore__podcasts', podcasts);
  setLazyValue('UsageStore__asl', adSkipsLeft);
  syncUsageDataLazily();
});

//
// Poll for currently playing podcast once a minute
//

let lastPodcastId: PodcastId | null = null;
let debugLastPoll = {};
let isPollingManagedByNativeApp = false;
export function pollForPodcastEveryMinute(isTriggeredByNativeApp = false) {
  // If native app queues this polling, then no longer queue it with setTimeout
  if (isTriggeredByNativeApp) isPollingManagedByNativeApp = true;
  if (isPollingManagedByNativeApp && !isTriggeredByNativeApp) return;

  const state = getState();
  const { podcastId } = currentEpisode.peek() || {};
  Object.assign(debugLastPoll, { state, podcastId, lastPodcastId, time: Date.now() });
  log('polling', state, podcastId);

  const isOnSamePodcast = lastPodcastId === podcastId;
  lastPodcastId = podcastId || null;

  if (isOnSamePodcast && podcastId && ['playing', 'seeking'].includes(state)) {
    // We've been listening to this same podcast for a minute, bump it
    usageActions.bumpMinutesListened(podcastId);
  }

  if (!isPollingManagedByNativeApp) setTimeout(pollForPodcastEveryMinute, 1000 * 60);
}

// Start polling soon after loading this page
if (typeof window !== 'undefined') {
  setTimeout(pollForPodcastEveryMinute, 1000 * 15);
}

//
// Sync to API (Supabase)
//

let isSyncing = false;
const fiveMinutes = 5 * 60 * 1000;

// Lazily sync usage data
export const syncUsageDataLazily = throttle(syncUsageDataToApi, fiveMinutes);

// Sync usage data immediately
export const syncUsageDataNow = () => {
  syncUsageDataLazily();
  syncUsageDataLazily.flush();
};

// Sync whenever user changes tabs (if a sync is pending)
onTabHidden(() => syncUsageDataLazily.flush());

export async function syncUsageDataToApi() {
  if (isSyncing) {
    log('Ignoring duplicate sync request');
    return;
  }

  const user = getUser();
  if (!user) {
    log("Not syncing b/c there's no user");
    return;
  }

  interface ResponseData {
    data: {
      updatedPodcastIds: string[];
      freeAdSkipsLeft: number;
      expiringAdSkipsLeft: number;
      foreverAdSkipsLeft: number;
      renewalTimestamp: number | undefined;
      subscriptionStatus: string | undefined;
      subscriptionStore: string | undefined;
      accountManagementUrl:
        | 'STRIPE_CUSTOMER_PORTAL'
        | `${string}://${string}`
        | undefined;
    };
    nonFatalErrors?: Record<string, any>;
  }

  const dataToSync = {
    userId: user.id,
    podcasts: useUsageStore.getState().podcasts,
    env: location.origin.endsWith('www.adblockpodcast.com') ? 'prod' : 'test',
    platform: platform.toLowerCase(),
  };

  isSyncing = true;
  log('Syncing usage data to API', dataToSync);
  let results: ResponseData['data'];
  try {
    const response = (
      await requestApi(
        'https://didgjhjnevdixtjltmho.functions.supabase.co/sync-usage-and-get-subscriber',
        dataToSync,
        { Authorization: `Bearer ${user.accessToken}` }
      )
    ).json as ResponseData;
    if (!response.data) throw new APIError('Invalid response');
    if (response.nonFatalErrors)
      reportError('Usage reporting failures', response.nonFatalErrors);
    results = response.data;
    isSyncing = false;
  } catch (error) {
    // Ensure isSyncing is always set to false
    isSyncing = false;
    reportError(error, 'Failed to report usage data');
    return;
  }

  // If user data has changed since sync started, don't do anything with result
  const currentUser = getUser();
  if (!currentUser || currentUser.id !== user.id) {
    log("Ignoring results of reporting prior user's usage");
    return;
  }

  const podcastsCloned: UsageStore['podcasts'] = JSON.parse(
    JSON.stringify(useUsageStore.getState().podcasts)
  );
  results.updatedPodcastIds.forEach((podcastId) => {
    const { adSkips: reportedAdSkips, minutesListened: reportedMinutesListened } =
      dataToSync.podcasts[podcastId];
    if (podcastsCloned[podcastId]) {
      podcastsCloned[podcastId].adSkips -= reportedAdSkips;
      podcastsCloned[podcastId].minutesListened -= reportedMinutesListened;
    }
  });
  // Remove entries without any usage that has yet to be reported
  Object.keys(podcastsCloned).forEach((podcastId) => {
    const { adSkips, minutesListened } = podcastsCloned[podcastId];
    if (!adSkips && !minutesListened) {
      // console.log('Clearing reported usage', podcastId, podcastsCloned[podcastId]);
      delete podcastsCloned[podcastId];
    }
  });
  useUsageStore.setState({
    podcasts: podcastsCloned,
    adSkipsLeft: {
      free: results.freeAdSkipsLeft,
      expiring: results.expiringAdSkipsLeft,
      forever: results.foreverAdSkipsLeft,
      renewalTimestamp: results.renewalTimestamp,
    },
    subscriptionStatus: results.subscriptionStatus,
    subscriptionStore: results.subscriptionStore,
    accountManagementUrl: results.accountManagementUrl,
  });
}

//
// Utils
//

function isObjectWithProperties(value: unknown, properties: string[]): value is object {
  return Boolean(
    typeof value === 'object' && value && properties.every((prop) => prop in value)
  );
}

function subtractFromValues(values: number[], amountToSubtract: number) {
  return values.map((val) => {
    if (amountToSubtract <= 0) return val;

    if (val >= amountToSubtract) {
      val = val - amountToSubtract;
      amountToSubtract = 0;
      return val;
    }

    amountToSubtract -= val;
    return 0;
  });
}

export function getAdSkipCredits(ads: { startTime: number; endTime: number }[]) {
  return sum(ads.map((ad) => durationToAdSkipCredits(ad.endTime - ad.startTime)));
}

function durationToAdSkipCredits(duration: number) {
  let adSkips = 0;
  if (duration >= 10) adSkips += 1;
  if (duration >= 40) adSkips += 1;
  if (duration > 90) adSkips += 1;
  return adSkips;
}

exposeOnWindow({ useUsageStore, debugLastPoll });
